var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("header", { staticClass: "site-header" }, [
    _c("div", { staticClass: "container-fluid" }, [
      _c("div", { staticClass: "site-header__inner" }, [
        _c("div", { staticClass: "site-header__contact" }, [
          _c(
            "a",
            {
              staticClass: "site-header__contact-link",
              attrs: { href: _vm.contact_url },
            },
            [_c("i", { staticClass: "icon icon-email" })]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "site-header__logo" }, [
          _c(
            "a",
            { staticClass: "site-header__logo-link", attrs: { href: "/" } },
            [
              _c("img", {
                staticClass: "logo",
                attrs: { src: _vm.logo, alt: _vm.logo_alt },
              }),
              _vm._v(" "),
              _c("img", {
                staticClass: "logo-dark",
                attrs: { src: _vm.logo_dark, alt: _vm.logo_alt },
              }),
            ]
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "site-header__menu-toggle-wrapper",
            on: {
              click: function ($event) {
                return _vm.toggleMenu()
              },
            },
          },
          [_vm._m(0)]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "site-header__nav" },
          [_c("tree", { attrs: { menu: _vm.getMenu() } })],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("button", { staticClass: "menu-toggle" }, [
      _c("span"),
      _vm._v(" "),
      _c("span"),
      _vm._v(" "),
      _c("span"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }