var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { class: { menu: !_vm.child, "sub-menu": _vm.child } },
    _vm._l(_vm.currentMenu, function (item, index) {
      return _c(
        "li",
        {
          key: index,
          class: [
            "menu-item",
            item.children ? "has-children" : "",
            item.open ? "active" : "",
            item.current ? "menu-item-current" : "",
            ...item.classes,
          ],
        },
        [
          item.children
            ? _c("span", {
                class: ["submenu-toggle"],
                on: {
                  click: function ($event) {
                    return _vm.toggleSubMenu(index)
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("a", { attrs: { href: item.url } }, [
            _vm._v(_vm._s(item.link_text)),
          ]),
          _vm._v(" "),
          item.children
            ? _c("tree", { attrs: { menu: item.children, child: true } })
            : _vm._e(),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }