<template>
    <header class="site-header">
        <div class="container-fluid">
            <div class="site-header__inner">
                <div class="site-header__contact">
                    <a class="site-header__contact-link" :href="contact_url"><i class="icon icon-email"></i></a>
                </div>
                <div class="site-header__logo">
                    <a class="site-header__logo-link" href="/">
                        <img class="logo" :src="logo" :alt="logo_alt">
                        <img class="logo-dark" :src="logo_dark" :alt="logo_alt">
                    </a>
                </div>
                <div class="site-header__menu-toggle-wrapper" v-on:click="toggleMenu()">
                    <button class="menu-toggle">
                        <span></span>
                        <span></span>
                        <span></span>
                    </button>
                </div>
                <div class="site-header__nav">
                    <tree :menu="getMenu()"/>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import Tree from "./Tree";
import Headroom from 'headroom.js';

export default {
    props: {
        'menu': null,
        'logo': String,
        'logo_dark': String,
        'logo_alt': String,
        'contact_url': String
    },

    data() {
        return {
            currentPath: window.location.pathname
        };
    },

    components: {
        Tree,
    },

    methods: {
        parseData(data) {
            this.menu = JSON.parse(data);
        },
        toggleMenu() {
            document.documentElement.classList.toggle('noscroll');
            document.body.classList.toggle('noscroll');
            document.body.classList.toggle('menu-open');
        },
        getMenu() {
            let menu = this.menu;

            menu.forEach(menuItem => {
                menuItem.current = this.isCurrentUrl(menuItem);
                if (menuItem.children) {
                    menuItem.children.forEach(submenuItem => {
                        submenuItem.current = this.isCurrentUrl(submenuItem);
                        if (submenuItem.current ) {
                            menuItem.open = true;
                        }
                    });
                }
            });

            return menu;
        },
        isCurrentUrl(menuItem) {
            let isCurrent = false;
            let a = document.createElement('a');
            a.href = menuItem.url;

            let linkPathName = a.pathname;
            if (linkPathName.charAt(0) === '/') {
                linkPathName = linkPathName.substring(1);
            }

            let currentPathName = this.currentPath;
            if (currentPathName.charAt(0) === '/') {
                currentPathName = currentPathName.substring(1);
            }

            if (linkPathName === currentPathName) {
                isCurrent = true;
            }

            return isCurrent;
        }
    },

    mounted() {
        let headroom = new Headroom(this.$el);
        headroom.init();
    }
}
</script>