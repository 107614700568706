<template>
    <ul :class="{ 'menu' : !child, 'sub-menu' : child }">
        <li v-for="(item, index) in currentMenu" :key="index"
            :class="[ 'menu-item', item.children ? 'has-children' : '', item.open ? 'active' : '', item.current ? 'menu-item-current' : '', ...item.classes ]">
            <span v-if="item.children" v-on:click="toggleSubMenu(index)" :class="['submenu-toggle']"></span>
            <a :href="item.url">{{ item.link_text }}</a>
            <tree v-if="item.children" :menu="item.children" :child="true"/>
        </li>
    </ul>
</template>

<script>
export default {
    name: 'tree',

    props: {
        'menu': null,
        'child': false,
    },

    data() {
        return {
            open: false,
            currentMenu: this.menu
        };
    },

    methods: {
        toggleSubMenu(index) {
            let value = Object.assign({}, this.currentMenu[index]);
            value.open = !value.open;
            this.$set(this.currentMenu, index, value);
        }
    },
}
</script>